svg {
  touch-action: none;
}

.jvectormap-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  touch-action: none;
}

.jvectormap-tip {
  // design changed by www.creative-tim.com
  position: absolute;
  display: none;
  color: $default-color;
  line-height: 1.5em;
  background: $white-color;
  border: none;
  border-radius: $border-radius-extreme;
  padding: 5px 10px;
  z-index: 1040;
}

.jvectormap-zoomin,
.jvectormap-zoomout,
.jvectormap-goback {
  position: absolute;
  left: 10px;
  border-radius: 3px;
  background: #292929;
  padding: 3px;
  color: white;
  cursor: pointer;
  line-height: 10px;
  text-align: center;
  box-sizing: content-box;
}

.jvectormap-zoomin,
.jvectormap-zoomout {
  width: 10px;
  height: 10px;
}

.jvectormap-zoomin {
  top: 10px;
}

.jvectormap-zoomout {
  top: 30px;
}

.jvectormap-goback {
  bottom: 10px;
  z-index: 1000;
  padding: 6px;
}

.jvectormap-spinner {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.jvectormap-legend-title {
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}

.jvectormap-legend-cnt {
  position: absolute;
}

.jvectormap-legend-cnt-h {
  bottom: 0;
  right: 0;
}

.jvectormap-legend-cnt-v {
  top: 0;
  right: 0;
}

.jvectormap-legend {
  background: black;
  color: white;
  border-radius: 3px;
}

.jvectormap-legend-cnt-h .jvectormap-legend {
  float: left;
  margin: 0 10px 10px 0;
  padding: 3px 3px 1px 3px;
}

.jvectormap-legend-cnt-h .jvectormap-legend .jvectormap-legend-tick {
  float: left;
}

.jvectormap-legend-cnt-v .jvectormap-legend {
  margin: 10px 10px 0 0;
  padding: 3px;
}

.jvectormap-legend-cnt-h .jvectormap-legend-tick {
  width: 40px;
}

.jvectormap-legend-cnt-h .jvectormap-legend-tick-sample {
  height: 15px;
}

.jvectormap-legend-cnt-v .jvectormap-legend-tick-sample {
  height: 20px;
  width: 20px;
  display: inline-block;
  vertical-align: middle;
}

.jvectormap-legend-tick-text {
  font-size: 12px;
}

.jvectormap-legend-cnt-h .jvectormap-legend-tick-text {
  text-align: center;
}

.jvectormap-legend-cnt-v .jvectormap-legend-tick-text {
  display: inline-block;
  vertical-align: middle;
  line-height: 20px;
  padding-left: 3px;
}
